import React from "react";
import "./Testimonials.css";

const testimonialsData = [
  {
    id: 1,
    title: "Client 1",
    description:
      "Directory of stainless steel fittings, hardware fitting suppliers and exporters. Get details of manufacturers of hardware fittings, from Hettich, Ebco, Hafele and more.",
  },
  {
    id: 2,
    title: "Client 2",
    description:
      "Supplier of plywood, block plywood, boards, finger jointed wood, flush door, and many more. Decorate your interior space with an exotic range of decorative laminates from a range of companies.",
  },
  {
    id: 3,
    title: "Client 3",
    description:
      "Get one step closer to furnishing your dream home and kitchen with our wide range of interior products. From kitchen chimneys and hobs to glass and mirrors, we’ve got it all covered.",
  },
];

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">Our Client Testimonials</h2>
      <div className="testimonials-grid">
        {testimonialsData.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <div className="avatar"></div>
            <h3>{testimonial.title}</h3>
            <p>{testimonial.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
