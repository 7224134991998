import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import "./OurBrands.css";

const OurBrands = () => {
  return (
    <div className="our-brands-container">
      <h2 className="heading">
        Our <span className="highlight">Brands</span>
      </h2>
      <div className="brands-slider">
        {Array(10)
          .fill()
          .map((_, index) => (
            <div className="slider-card" key={index}>
              <img
                src="/static/media/front5.37b6da905599f9c5bd5e.jpg"
                alt={`Brand ${index + 1}`}
                className="slider-image"
              />
            </div>
          ))}
      </div>
      <h2 className="relationship-text">
        This could be the beginning of a beautiful relationship
      </h2>
      {/* Using Link for internal navigation */}
      <Link to="/start-your-project" className="start-project-btn">
        Start Your Project
      </Link>
    </div>
  );
};

export default OurBrands;
