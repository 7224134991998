import React from "react";
// import './css/About.css';
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import DesignCard from "./DesignCard.jsx";
import WhyChooseUs from "./WhyChooseUs.jsx";
import DesignProcess from "./DesignProcess.jsx";
import OurBrands from "./OurBrands.jsx";
import Testimonials from "./Testimonials.jsx";
import FrameComponent from "./FrameComponent.jsx";
function Homepage() {
  return (
    <>
      <Navbar />
      <FrameComponent/>
      <DesignCard />
      <WhyChooseUs />
      <DesignProcess />
      <OurBrands />
      <Testimonials />
      <Footer />
    </>
  );
}
export default Homepage;
