import React from "react";
import "./DesignProcess.css";

const DesignProcess = () => {
  const steps = [
    {
      imgSrc: "/img/image001.png", // Replace with your image URL
      title: "Understanding your need",
    },
    {
      imgSrc: "/img/image002.png", // Replace with your image URL
      title: "Let build together your future home",
    },
    {
      imgSrc: "/img/image003.png", // Replace with your image URL
      title: "Cost Optimization & Planning",
    },
    {
      imgSrc: "/img/image004.png", // Replace with your image URL
      title: "Handover the project with warranty",
    },
  ];

  return (
    <div className="design-process">
      <h2 className="design-process-title">Our Design Process - How It Works</h2>
      <div className="design-process-steps">
        {steps.map((step, index) => (
          <div className="design-step" key={index}>
            <img src={step.imgSrc} alt={step.title} className="design-step-image" />
            <p className="design-step-title">{step.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignProcess;
