import React, { useState } from "react";
import "./FrameComponent.css";

const FrameComponent = () => {
  const [step, setStep] = useState(1);
  const [roomCounts, setRoomCounts] = useState({
    kitchen: 0,
    bedroom: 0,
    washingRoom: 0,
    dining: 0,
    livingRoom: 0,
  });

  const handleNext = () => {
    if (step === 3) {
      console.log("Estimate requested:", { ...roomCounts });
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () =>
    setStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));

  const updateRoomCount = (room, delta) => {
    setRoomCounts((prevCounts) => ({
      ...prevCounts,
      [room]: Math.max(prevCounts[room] + delta, 0), // Prevent negative counts
    }));
  };

  return (
    <div className="frame-container">
      {/* Left Section */}
      <div className="frame-left">
        <img src="/img/frame.png" alt="Interior" className="interior-image" />
      </div>

      {/* Right Section */}
      <div className="frame-right">
        <div className="form-header">
          <img
            src="/img/calculator.png"
            alt="Price Calculator"
            className="header-logo"
          />
          <h2>
            PRICE
            <br />
            CALCULATOR
          </h2>
        </div>
        <p>Now Get Premium & Luxury Home Interiors @ Unbelievable Price!</p>
        {step === 1 && (
          <div className="form-content">
            <form>
              <label>
                <input type="radio" name="option" value="1bhk" /> 1 BHK
              </label>
              <label>
                <input type="radio" name="option" value="2bhk" /> 2 BHK
              </label>
              <label>
                <input type="radio" name="option" value="3bhk" /> 3 BHK
              </label>
              <label>
                <input type="radio" name="option" value="3plusbhk" /> 3+ BHK
              </label>
              <label>
                <input type="radio" name="option" value="villa" />{" "}
                Villa/Farmhouse
              </label>
            </form>
          </div>
        )}
        {step === 2 && (
          <div className="form-content">
            {Object.keys(roomCounts).map((room) => (
              <div className="room-counter" key={room}>
                <span>
                  {room
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}
                </span>
                <div className="room-counter-controls">
                  <button onClick={() => updateRoomCount(room, -1)}>-</button>
                  <span>{roomCounts[room]}</span>
                  <button onClick={() => updateRoomCount(room, 1)}>+</button>
                </div>
              </div>
            ))}
          </div>
        )}
        {step === 3 && (
          <div className="form-content">
            <form className="step3-form">
              <input type="text" placeholder="Name" className="form-input" />
              <input type="email" placeholder="Email" className="form-input" />
              <input type="tel" placeholder="Phone" className="form-input" />
              <input type="text" placeholder="City" className="form-input" />
              <input type="text" placeholder="Pincode" className="form-input" />
            </form>
          </div>
        )}
        <div
          className="button-container"
          style={
            step === 1
              ? { justifyContent: "flex-end" }
              : { justifyContent: "space-between" }
          }
        >
          {step > 1 && (
            <button className="back-button" onClick={handleBack}>
              Back
            </button>
          )}
          <button className="next-button" onClick={handleNext}>
            {step === 3 ? "Get Estimate" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
