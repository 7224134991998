import React from 'react';
import './WhyChooseUs.css'; // Import the CSS file

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us-container">
      <div className="text-section">
        <h2>
          <span className="highlight">Why</span> Choose Us
        </h2>
        <ul>
          <li>End-to-end interior solution</li>
          <li>100+ Design Experts</li>
          <li>8+ year Warranty on material</li>
          <li>60 days delivery</li>
        </ul>
      </div>
      <div className="image-section">
        <img
          src="/img/whychooseus-removebg.png"
          alt="Interior Design Illustration"
          className="illustration"
        />
      </div>
    </div>
  );
};

export default WhyChooseUs;
