import React from "react";
import "./DesignCard.css";

const cardData = [
  { title: "Kids Room", image: "/img/sample_photos/home/restaurant58.jpg" },
  { title: "Dining Room", image: "/img/sample_photos/home/restaurant49.jpg" },
  {
    title: "Modular Kitchen",
    image: "/img/sample_photos/home/restaurant76.jpg",
  },
  {
    title: "Deluxe Bedroom",
    image: "/static/media/front5.37b6da905599f9c5bd5e.jpg",
  },
  { title: "Wash Room", image: "/img/sample_photos/home/restaurant11.jpg" },
];

const DesignCard = () => {
  return (
    <div className="design-container">
      <h2 className="design-title">
        What <span className="design-highlight">We Design</span>
      </h2>
      <div className="design-card-container">
        {cardData.map((card, index) => (
          <div key={index} className="design-card">
            <img
              src={card.image}
              alt={card.title}
              className="design-card-image"
            />
            <div className="design-card-overlay">
              <h3 className="design-card-title">{card.title}</h3>
            </div>
          </div>
        ))}
      </div>
      <div className="design-footer">
        <a href={"/interior-design/"} className="design-link">
          <span className="design-link-red">Explore Our</span>{" "}
          <span className="design-link-black">Designs</span>
        </a>
      </div>
    </div>
  );
};

export default DesignCard;
